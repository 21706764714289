<template>
  <div class="charge">
    <div class="charge-head">
      <div class="fx-v-center fx-m-between ft-medium">
        <div>
          <img src="@/assets/img/coin.png" alt />
          <span>充值账户： {{ account }}</span>
        </div>
        <div class="dqye">
          <span>{{ balance.desc }}</span>
          <span class="ft-sz-22" style="color: #fed638">{{ balance.value }}</span>
          <span>{{ " " + balance.unit }}</span>
        </div>
      </div>
    </div>
    <div class="charge-body">
      <choice-bar :list="cardList" @pay="toPay"></choice-bar>
    </div>
  </div>
</template>

<script>
import choiceBar from './components/choice-bar';

import service from './api';

export default {
  data() {
    return {
      chargeType: 0,
      tabs: [],
      reportChargeList: [],
      promoteChargeList: []
    };
  },
  components: {
    choiceBar
  },
  computed: {
    account() {
      let user = this.$store.state.XINYOU_SH_USER || {};
      let { username = '', mobile = '' } = user;
      return username ? username + '-' + mobile : mobile;
    },
    // 余额
    balance() {
      let { chargeType = 0 } = this;
      let accountBalance = this.$store.state.balance || 0;
      let promoteBalance = this.$store.state.promoteCoin || 0;
      return chargeType === 0
        ? { desc: '当前余额：', value: accountBalance, unit: '元' }
        : { desc: '星币余额：', value: promoteBalance, unit: '个' };
    },
    cardList() {
      let { chargeType = 0, reportChargeList = [], promoteChargeList = [] } = this;
      return chargeType === 0 ? reportChargeList : promoteChargeList;
    }
  },
  methods: {
    // 获取账号充值选项
    getCards() {
      return service.choices().then(res => {
        this.reportChargeList = res.map(item => {
          item.center = '￥' + (item.amount / 100);
          item.bottom = '赠送' + item.giftAmount / 100 + '元';
          item.price = (item.amount / 100) || 0;
          return item;
        });
      });
    },
    // 支付
    async toPay(data) {
      data.type = this.chargeType === 0 ? 1 : 2
      const res = await service.pay(data);
      window.location.href = res.payUrl
    }
  },
  created() {
    let { type = 0 } = this.$route.query
    this.chargeType = Number(type)
    this.getCards();
    // 充值Tab
    this.tabs.push('账号充值')
  }
};
</script>

<style lang="scss" scoped>
.charge {
  background-color: #fff;
  &-head {
    background-color: #27b0d6;
    color: #fff;
    text-align: left;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    &-tabs {
      margin-top: 30px;
      .tab {
        width: 100px;
        padding: 5px 0;
        margin-right: 10px;
        text-align: center;
        border-radius: 3px 3px 0 0;
        color: #27b0d6;
        background-color: rgba(255, 255, 255, 0.4);
        cursor: pointer;
        &.active {
          color: #27b0d6;
          background-color: #fff;
        }
      }
    }
  }
  &-body {
    padding: 20px 20px 30px;
    background-color: #fff;
  }
}
@media screen and (max-width: 480px) {

.fx-m-between{
  display: block;
}
.dqye{
  display: none;
}
}
</style>
